import React, {Component} from 'react'
import {Col, Row} from "reactstrap";


import intro from '../../resources/images/jpg/Intro-sm.jpg'
import qc from '../../resources/images/jpg/QC-sm.jpg'
import shipping from '../../resources/images/jpg/Shipping-sm.jpg'

import './Front.css'

class Front extends Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
    }


    updateWindowDimensions = () => {
        this.setState({width: window.innerWidth});
    };

    render() {
        if (this.state.width > 767) {
            return (
                <div style={{backgroundColor: "#f9f9f9"}}>
                    <Row>
                        <Col md={4} lg={4} style={{textAlign: "center", verticalAlign: "middle"}}>
                            <div style={{
                                position: "relative",
                                top: "50%",
                                transform: "translateY(-50%)",
                                border: "1px solid rgb(204,204,204)"
                            }}>
                                <h2 style={{verticalAlign: "middle", margin: "10px 30px 10px 30px"}}>Exporting
                                    Textile Products Since 2000</h2>
                            </div>
                        </Col>
                        <Col md={8} lg={8} style={{
                            paddingRight: "0",
                            height: "60vh",
                            background: "linear-gradient(to right, rgba(255,0,0,0), rgba(0,0,0,1))"
                        }}>
                            <img src={intro} style={{width: "100%", height: "100%", objectFit: "cover", opacity: "0.5"}}
                                 alt="intro"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} lg={8} style={{
                            paddingLeft: "0",
                            height: "60vh",
                            background: "linear-gradient(to left, rgba(255,0,0,0), rgba(0,0,0,1))"
                        }}>
                            <img src={qc} style={{width: "100%", height: "100%", objectFit: "cover", opacity: "0.5"}}
                                 alt="quality"/>
                        </Col>
                        <Col md={4} lg={4} style={{textAlign: "center", verticalAlign: "middle"}}>
                            <div style={{
                                position: "relative",
                                top: "50%",
                                transform: "translateY(-50%)",
                                border: "1px solid rgb(204,204,204)"
                            }}>
                                <h2 style={{verticalAlign: "middle", margin: "10px 30px 10px 30px"}}>Maintaining High
                                    Quality</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} lg={4} style={{textAlign: "center", verticalAlign: "middle"}}>
                            <div style={{
                                position: "relative",
                                top: "50%",
                                transform: "translateY(-50%)",
                                border: "1px solid rgb(204,204,204)"
                            }}>
                                <h2 style={{verticalAlign: "middle", margin: "10px 30px 10px 30px"}}>Shipping All Over
                                    The World</h2>
                            </div>
                        </Col>
                        <Col md={8} lg={8} style={{
                            paddingRight: "0",
                            height: "60vh",
                            background: "linear-gradient(to right, rgba(255,0,0,0), rgba(0,0,0,1))"
                        }}>
                            <img src={shipping}
                                 style={{width: "100%", height: "100%", objectFit: "cover", opacity: "0.5"}}
                                 alt="intro"/>
                        </Col>
                    </Row>
                </div>
            )
        }
        else {
            return (
                <div style={{backgroundColor: "#f9f9f9"}}>
                    <Row>
                        <Col md={12} lg={12} style={{
                            paddingRight: "0",
                            paddingLeft: "0"
                        }}>
                            <div className="img-container">
                                <img src={intro}
                                     style={{width: "100%", height: "47vh", objectFit: "cover" }}
                                     alt="intro"
                                     className="img-dark"/>
                                <div style={{border: "1px solid rgb(204,204,204)"}} className="centered">
                                    <h3 style={{verticalAlign: "middle", margin: "10px 30px 10px 30px"}}>
                                        Exporting Textile Products Since 2000
                                    </h3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12} style={{
                            paddingRight: "0",
                            paddingLeft: "0"
                        }}>
                            <div className="img-container">
                                <img src={qc}
                                     style={{width: "100%", height: "46vh", objectFit: "cover" }}
                                     alt="intro"
                                     className="img-dark"/>
                                <div style={{border: "1px solid rgb(204,204,204)"}} className="centered">
                                    <h3 style={{verticalAlign: "middle", margin: "10px 30px 10px 30px"}}>
                                        Maintaining High Quality
                                    </h3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12} style={{
                            paddingRight: "0",
                            paddingLeft: "0"
                        }}>
                            <div className="img-container">
                                <img src={shipping}
                                     style={{width: "100%", height: "46vh", objectFit: "cover" }}
                                     alt="intro"
                                     className="img-dark"/>
                                <div style={{border: "1px solid rgb(204,204,204)"}} className="centered">
                                    <h3 style={{verticalAlign: "middle", margin: "10px 30px 10px 30px"}}>
                                        Shipping All Over The World
                                    </h3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }
}

export default Front