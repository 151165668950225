import React, { Component } from 'react'
import { Card, CardBody, CardText, CardTitle } from "reactstrap";

class About extends Component {
    render() {
        return (
            <div>
                <h3 >About</h3>
                <hr />
                <Card style={{ margin: "6px 2px 6px 2px", border: 0 }}>
                    <CardBody>
                        <CardTitle><strong>History</strong></CardTitle>
                        <CardText>
                            Duster Group is a Pakistan based company committed to producing and exporting high quality textile items all over the world.
                            This commitment has made the company a leading exporter of yellow dusters, kitchen towels and more!
                            <br /><br />
                            The management of the company has more than <strong>two decades of experience</strong> in the textile industry and ensures that all products meet the demands of our customers
                            by following a <strong>stringent quality control system</strong> in order to obtain optimum results.
                        </CardText>
                    </CardBody>
                </Card>
                <Card style={{ margin: "6px 2px 6px 2px", border: 0 }}>
                    <CardBody>
                        <CardTitle><strong>Shipping</strong></CardTitle>
                        <CardText>
                            Our high standards along with our reputation of shipping orders in a timely manner have allowed
                            us to form close ties with companies from Europe, North America and South America.
                            <br /><br />
                            <b>Shipping Methods:</b>&nbsp;&nbsp;&nbsp; Air and Sea<br />
                            <b>Payment Methods:</b>&nbsp;&nbsp;&nbsp; LC at Sight and Advanced Payment<br />
                            <b>Minimum Shipping:</b>&nbsp;&nbsp; LCL and FCL<br />
                            <b>Delivery Time:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 30 to 45 days after order confirmation<br />
                            <br />
                            <i>Samples are available upon request and on payment of sample charges.</i>
                        </CardText>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default About