import React, {Component} from 'react'
import {Card, CardBody, CardImg, CardTitle, Col, Row} from "reactstrap";

let productList = [
    {
        name: "Surgical Masks",
        description: "Surgical Masks Made From Woven Material",
        imgName: "FM1"
    },
    {
        name: "Yellow Duster",
        description: "Plain and Checked",
        imgName: "YD1-sm",
    },
    {
        name: "Scottish Duster",
        description: "Plain and Checked",
        imgName: "YD2-sm"
    },
    {
        name: "Printed Bag",
        description: "Printed Bags",
        imgName: "GB2-sm"
    },
    {
        name: "Grey Bag",
        description: "Bag with Short Handles",
        imgName: "GB3-sm"
    },
    {
        name: "Kitchen Towel",
        description: "Kitchen Woven Towels",
        imgName: "KT2-sm"
    },
    {
        name: "Stick Mop",
        description: "Stick Mop",
        imgName: "SM1-sm"
    },
    {
        name: "Jacquard Tea Towel",
        description: "Jacquard Tea Towel",
        imgName: "JT1-sm"
    },
    {
        name: "Mono Check Tea Towel",
        description: "Mono Check Tea Towel",
        imgName: "MT1-sm"
    },
    {
        name: "Striped Dish Cloth",
        description: "Striped Dish Cloth",
        imgName: "SD1-sm"
    },
    {
        name: "Wipers",
        description: "Wipers",
        imgName: "WI1-sm"
    },
    {
        name: "Kentucky Mop Twine",
        description: "Kentucky Mop Twine",
        imgName: "KM1-sm"
    },
    {
        name: "Socket Mop",
        description: "Socket Mop",
        imgName: "SM2-sm"
    },
    {
        name: "Tea Towel",
        description: "Tea Towel",
        imgName: "TT1-sm"
    },
    {
        name: "Cotton Yard Dyed Table Towel",
        description: "Cotton Yard Dyed Table Towel",
        imgName: "CYT1"
    },
    {
        name: "Customized Cotton Bags",
        description: "Customized Cotton Bags",
        imgName: "CCB1"
    },
    {
        name: "Kitchen Tea Towel",
        description: "Kitchen Tea Towel",
        imgName: "KTT1"
    },
    {
        name: "White Dish Towel",
        description: "White Dish Towel",
        imgName: "WDT1"
    },
    {
        name: "Cotton Promotional Bags",
        description: "Cotton Promotional Bags",
        imgName: "CPB1"
    },
    {
        name: "Eco Friendly Shopping Bags",
        description: "Eco Friendly Shopping Bags",
        imgName: "EFB1"
    },
    {
        name: "Yarn Dyed Dish Towel",
        description: "Yarn Dyed Dish Towel",
        imgName: "YDD1"
    },
    {
        name: "Yarn Dyed Kitchen Towel",
        description: "Yarn Dyed Kitchen Towel",
        imgName: "YDK1"
    },
    {
        name: "Stockinet Roll",
        description: "Stockinet Roll",
        imgName: "SR1"
    },
    {
        name: "Dish Cloth",
        description: "Dish Cloth",
        imgName: "DC1"
    },
    {
        name: "Microfiber Cloth",
        description: "Microfiber Cloth",
        imgName: "MC3"
    },
];

class Product extends Component {
    render() {
        return (
            <Col xs={12} sm={6} md={6} lg={6} xl={4} style={{marginBottom: "1em", paddingLeft:5, paddingRight:5}}>
                <Card style={{marginLeft: "2px", marginRight:"2px", border:0, borderBottom:"3px solid #000044"}}>
                    <CardImg top width="100%"
                             src= {window.location.origin + "/resources/images/jp2/" + this.props.imgName + ".jp2" || "https://placeholdit.imgix.net/~text?txtsize=33&txt=318%C3%97180&w=318&h=180"}
                             alt="Product Image"/>
                    <CardBody>
                        <CardTitle>{this.props.name || "Product Name"}</CardTitle>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

class Products extends Component {
    render() {
        return (
            <div>
                <h3>Products</h3>
                <hr/>
                <Row>
                    {productList.map(x => <Product name={x.name} description={x.description} imgName={x.imgName}/>)}
                </Row>
            </div>
        )
    }
}

export default Products