import React, { Component } from 'react'
import { Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Collapse } from "reactstrap";
import { Link } from 'react-scroll'

import './Header.css'

class Header extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false
        };
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <div className="header">
                <Navbar color="transparent" dark expand="md" className="colored-margin">
                    <Link className="link-hover" to="front" spy={true} smooth={true} duration={500}><NavbarBrand className="pl-lg-5 pl-md-5 pl-sm-4" style={{ color: "white" }}>
                        {window.innerWidth > 767 ? <h1><span style={{ verticalAlign: "middle" }}>Duster Group</span></h1> : <h3><span style={{ verticalAlign: "middle" }}>Duster Group</span></h3>}
                    </NavbarBrand></Link>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto pr-lg-5 pr-md-5 pr-sm-4" navbar>
                            <NavItem>
                                <NavLink><Link className="link-hover" to="products" spy={true} smooth={true} duration={500} offset={-80}>Products</Link></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink><Link className="link-hover" to="about" spy={true} smooth={true} duration={500} offset={-80}>About</Link></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink><Link className="link-hover" to="contact" spy={true} smooth={true} duration={500} offset={-80}>Contact</Link></NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}

export default Header;