import React, {Component} from 'react'
import {Element, Events, animateScroll as scroll, scrollSpy} from 'react-scroll'
import Sticky from 'react-sticky-el'
import {Col, Row} from "reactstrap";

import About from './components/About/About'
import Contact from './components/Contact/Contact'
import Front from './components/Front/Front'
import Header from './components/Header/Header'
import Products from './components/Products/Products'

import './App.css'


class App extends Component {

    componentDidMount() {

        Events.scrollEvent.register('begin', function (to, element) {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function (to, element) {
            console.log("end", arguments);
        });

        scrollSpy.update();

    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    scrollToTop = () => {
        scroll.scrollToTop();
    }

    handleSetActive = (to) => {
        console.log(to);
    }

    render() {
        return (
            <div className="App">
                <Sticky style={{zIndex:100}}>
                    <Header/>
                </Sticky>
                <div className="container-fluid">
                    <Element name="front">
                        <Front/>
                    </Element>
                    <br/>
                    <br/>
                    <br/>
                    <Row>
                        <Col xs={0} sm={1} md={3} lg={3}/>
                        <Col xs={0} sm={10} md={6} lg={6}>
                            <Element name="products">
                                <Products/>
                            </Element>
                        </Col>
                        <Col xs={0} sm={1} md={3} lg={3}/>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={0} sm={1} md={3} lg={3}/>
                        <Col xs={0} sm={10} md={6} lg={6}>
                            <Element name="about">
                                <About/>
                            </Element>
                        </Col>
                        <Col xs={0} sm={1} md={3} lg={3}/>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={0} sm={1} md={3} lg={3}/>
                        <Col xs={0} sm={10} md={6} lg={6}>
                            <Element name="contact">
                                <Contact/>
                            </Element>
                        </Col>
                        <Col xs={0} sm={1} md={3} lg={3}/>
                    </Row>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        );
    }
}

export default App;
