import React, { Component } from 'react'
import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap";
import FA from 'react-fontawesome'

class Contact extends Component {
    render() {
        return (
            <div>
                <h3>Contact</h3>
                <hr />
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={7}
                        style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 5, paddingBottom: 5 }}>
                        <Card style={{ marginLeft: "2px", marginRight: "2px", border: 0 }}>
                            <CardBody>
                                <CardTitle><strong>Address</strong></CardTitle>
                                <CardText>
                                    <Row>
                                        <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                                            <FA name="map-marker" size="2x" />
                                        </Col>
                                        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                            <a href="https://goo.gl/maps/SrDYZa66uzF2" target="_blank" rel="noopener noreferrer">
                                                2nd Floor, Building No. 11/C <br />
                                                Lane 11, Bukhari Commercial Area<br />
                                                Phase 6, DHA <br />
                                                Karachi, Pakistan <br />
                                                75500
                                            </a>
                                        </Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={5}
                        style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 5, paddingBottom: 5 }}>
                        <Card style={{ marginLeft: "2px", marginRight: "2px", border: 0 }}>
                            <CardBody>
                                <CardTitle><strong>Get in Touch</strong></CardTitle>
                                <CardText>
                                    <FA name="at" size="1x" />&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:info@dustergroup.com">info@dustergroup.com</a>
                                    <br />
                                    <FA name="phone" size="1x" />&nbsp;&nbsp;&nbsp;&nbsp;<a href="tel:+922135242563">+92-213-5242563</a>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Contact